export enum CourseContentActivityType {
  Case = 'case',
  Lesson = 'lesson',
  Quiz = 'quiz',
  KickoffQuiz = 'kickoff_quiz',
  MasteryQuiz = 'mastery_quiz',
  Comparison = 'comparison',
}

export enum CourseType {
  Course = 'course',
  Workshop = 'workshop',
}

export enum CourseSubViewType {
  Course = 'course',
  Lesson = 'lesson',
  Quiz = 'quiz',
  Case = 'case',
  Comparison = 'comparison',
}

export enum CourseChapterType {
  Regular = 'regular',
  Kickoff = 'kickoff',
  Mastery = 'mastery',
}

export enum CoursesTab {
  WhatToWatchNext = 'what-to-watch-next',
  ContinueLearning = 'continue-learning',
  MyCourses = 'my-courses',
  Certificates = 'certificates',
  Completed = 'completed',
}

export const ComparisonId = -1000;

export const KickoffHintViewed = 'kickoff_hint_viewed';
